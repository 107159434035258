import React from "react";

import Tooltip from "@mui/material/Tooltip";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

// ChartJS.defaults.datasets.bar.maxBarThickness = 20;

// const options = {
//   indexAxis: "y",
//   elements: {
//     bar: {
//       borderWidth: 0,
//       borderRadius: 50,
//     },
//   },
//   scales: {
//     x: {
//       display: false,
//       grid: {
//         display: false,
//         color: "#fff",
//       },
//     },

//     y: {
//       color: "#fff",
//       grid: {
//         // display: false,
//         color: "#fff",
//       },
//     },
//   },
//   responsive: true,
//   maintainAspectRatio: true,
//   plugins: {
//     legend: {
//       display: false,
//     },
//     title: {
//       display: false,
//     },
//   },
// };

// const labels = ["FCL", "LCL", "LSE"];

// const data = {
//   labels,
//   //   barPercentage: 0.5,
//   //   categoryPercentage: 0.5,

//   datasets: [
//     {
//       label: "Total",
//       data: [100, 80, 40],
//       borderColor: "#006bff",
//       backgroundColor: "#006bff",
//     },
//   ],
// };
function HorizontalBarChart({ datasets }) {
  const { maxValue, data = [] } = datasets;
  return (
    <>
      {data.map((item, index) => {
        const barPercentage = (parseInt(item.value) * 100) / parseInt(maxValue);
        // if (!item.lable) return;
        return (
          <div className="horizontal-bar" key={index}>
            <div className="d-flex justify-content-between">
              <p>{item.lable}</p>
              {/* <p>{item.value}</p> */}
            </div>
            <Tooltip
              placement="top"
              title={`${item.lable}: ${item.value}`}
              arrow
            >
              <div
                className="progress horizontal-progress"
                key={item.value + item.lable}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${barPercentage}%` }}
                  //   aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax={maxValue}
                />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
}

export default HorizontalBarChart;
