import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routeConstant } from "../../routing";
import "./component/styles.css";
import { withTranslation } from "react-i18next";

function Sidebar(props) {
  const navigate = useNavigate();
  const [showclass, setShowclass] = useState("Overview");
  const location = useLocation();
  const reportcard = [
    {
      title: "GeneralView",
      route: `${routeConstant.REPORT}/${routeConstant.OVERVIEW}`,
    },
    {
      title: "Volumetransported",
      route: `${routeConstant.REPORT}/${routeConstant.TRANSPORT}`,
    },
    {
      title: "ExportData",
      route: `${routeConstant.REPORT}/${routeConstant.DATAEXPORT}`,
    },
    {
      title: "GeneralStatistics",
      route: `${routeConstant.REPORT}/${routeConstant.GENERAL}`,
    },
    {
      title: "Deadlineanalysis",
      route: `${routeConstant.REPORT}/${routeConstant.DEADLINE}`,
    },
    {
      title: "Incotermanalysis",
      route: `${routeConstant.REPORT}/${routeConstant.INCOTERM}`,
    },
    // {
    //   title: "Demurrage",
    //   route: `${routeConstant.REPORT}/${routeConstant.DEMURRAGE}`,
    // },
  ];
  const handleClick = (item) => {
    navigate(item.route);
    const temp = item.title.split(" ");
    setShowclass(temp[0]);
  };
  const { t } = props;

  return (
    <div className="report">
      <div className="report-headline">
        <p>{t("Report")}</p>
      </div>
      {reportcard.map((data, index) => {
        return (
          <div
            key={index}
            className={
              location.pathname.includes(data.route)
                ? "data active-field-report"
                : "data"
            }
            onClick={() => handleClick(data)}
          >
            <p
              className={
                location.pathname.includes(data.route)
                  ? "props-text-report active-color"
                  : "props-text-report"
              }
            >
              {t(data.title)}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default withTranslation()(Sidebar);
