import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { withTranslation } from "react-i18next";
import BtnOutline from "../../../components/button/BtnOutline";
import "../process.css";
import { basename } from "../../../Config";
import { useLocation, useNavigate } from "react-router-dom";
import { Date } from "../../../utils/moment";
import { calculateAmmount } from "../../../utils/utility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import Nodata from "../../../components/Nodata";
import urlResolver from "../../../utils/urlResolver";

const ShipmentDetailsCard = ({ detailsdata, loading, t }) => {
  const [showmore, setShowmore] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [nodata, setNoData] = useState(true);

  console.log("loadinginCard", loading);
  const navigation = useNavigate();
  const location = useLocation();

  const clickShowmore = () => {
    showmore ? setShowmore(false) : setShowmore(true);
  };

  useEffect(() => {
    if (!Object.keys(detailsdata).length) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [detailsdata]);

  useEffect(() => {
    setShowmore(true);
  }, [detailsdata]);

  const poNumberSplit = (poNum) => {
    if (poNum?.split(",")?.length > 2) {
      let tempPoNum = poNum.split(",");
      let orderRef = tempPoNum.join(", ");
      return <p className="test-para">{orderRef ?? "N/A"}</p>;
    } else {
      return (
        <p className="test-para">{detailsdata?.OrderReference ?? "N/A"}</p>
      );
    }
  };

  const getVesselVoyageText = (vessel, voyage, t) => {
    if (!vessel && !voyage) {
      return t("N/A");
    }
    return `${vessel ?? ""}${vessel && voyage ? "-" : ""}${voyage ?? ""}`;
  };

  const getLoadDischargeText = (loadPort, discharge, voyage, t) => {
    if (!loadPort && !discharge && !voyage) {
      return t("N/A");
    }
    return `${loadPort ?? ""}${loadPort || discharge ? " ✈ " : ""}${discharge ?? ""}${(loadPort || discharge) && voyage ? ", " : ""}${voyage ?? ""}`;
  };

  console.log("detailsdatadetailsdata", detailsdata);

  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={365}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      ) : (
        <>
          <div className="mid-container-process d-flex">
            {nodata ? (
              <Nodata />
            ) : (
              <>
                <div className="process-page-image d-flex">
                  {/* {location.state.item.TransportMode ==="SEA"?<img src={`${basename}/icon/icon-plane-blue.svg`} />:} */}
                  {location?.state?.item?.TransportMode === "SEA" ? (
                    <img src={urlResolver("icon/SEABACKGROUNDBLUE.svg")} />
                  ) : location?.state?.item?.TransportMode === "AIR" ? (
                    <img src={urlResolver("icon/AIRBACKGROUNDBLUE.svg")} />
                  ) : location?.state?.item?.TransportMode === "ROA" ? (
                    <img src={urlResolver("icon/ROADBACKGROUNDBLUE.svg")} />
                  ) : null}
                  <div>
                    <h3 className="test-head">{t("PONumber")}</h3>
                    {/* removed this code due to string's not containg space sometimes and makes the ui misbehave */}
                    {/* <p className="test-para">
                  {detailsdata?.OrderReference ?? "N/A"}
                </p> */}
                    {poNumberSplit(detailsdata?.OrderReference)}
                  </div>
                </div>
                <div className="test-section ">
                  <div>
                    <h4 className="test-section-head">{t("ETD")} </h4>
                    <p className=" test-section-para">
                      {Date(detailsdata?.ETD ?? null)}
                    </p>
                  </div>
                  <div>
                    <h4 className="test-section-head">{t("ETA")}</h4>
                    <p className="test-section-para">
                      {Date(detailsdata?.ETA ?? null)}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          {nodata ? (
            <div
              style={{
                marginBottom: "1rem",
              }}
            ></div>
          ) : (
            <div className="last-container">
              <div className="last-container-first-child">
                <div className="last-container-child">
                  <p className="last-container-paraone2">{t("Shipper")}</p>
                  <p
                    className="color-blue2 last-container-childmid"
                    title={detailsdata?.ConsignorName ?? t("N/A")}
                  >
                    {detailsdata?.ConsignorName ?? t("N/A")}
                  </p>
                </div>
                <div className="d-flex " style={{ flexWrap: "wrap" }}>
                  <div
                    className={
                      location?.state?.item?.TransportMode === "AIR"
                        ? "d-none"
                        : "last-sectionlast"
                    }
                  >
                    {location?.state?.item?.TransportMode === "AIR" ? null : (
                      <>
                        <p className="last-container-paraone2">
                          {t("Mercante Number")}{" "}
                        </p>
                        <p
                          className="color-blue2 last-container-childmid"
                          title={detailsdata?.CEMercanteNumber ?? t("N/A")}
                        >
                          {detailsdata?.CEMercanteNumber ?? t("N/A")}

                          {detailsdata.CEMercanteNumber ? (
                            <>
                              <span
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    detailsdata?.CEMercanteNumber ?? t("N/A")
                                  );
                                  setIsCopied(!isCopied);
                                  // setTimeout(() => {
                                  //   setIsCopied(false);
                                  // }, 3000);
                                }}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                              >
                                {isCopied ? (
                                  <ContentCopyTwoToneIcon />
                                ) : (
                                  <ContentCopyIcon />
                                )}
                              </span>
                              {/* <img
             
              src=
              alt={t("N/A")}
              title={t("Click to copy")}
             
            /> */}
                            </>
                          ) : null}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="last-sectionlast">
                    <p className="last-container-paraone2">{t("Origin")}</p>
                    <p
                      className="color-blue2 last-container-childmid"
                      title={`${detailsdata?.OriginPortName ?? t("N/A")} , ${
                        detailsdata?.OriginCountryName ?? t("N/A")
                      }`}
                    >
                      {`${detailsdata?.OriginPortName ?? t("N/A")} , ${
                        detailsdata?.OriginCountryName ?? t("N/A")
                      }`}
                    </p>
                  </div>
                  <div className="last-sectionlast">
                    <p className="last-container-paraone2">
                      {t("Destination")}
                    </p>
                    <p
                      className="color-blue2 last-container-childmid"
                      title={`${
                        detailsdata?.DestinationPortName ?? t("N/A")
                      }, ${detailsdata?.DestinationCountryName ?? t("N/A")}`}
                    >{`${detailsdata?.DestinationPortName ?? t("N/A")}, ${
                      detailsdata?.DestinationCountryName ?? t("N/A")
                    }`}</p>
                  </div>
                  {/* {location?.state?.item?.TransportMode === "AIR" ? (
                    <div className="last-sectionlast"></div>
                  ) : null} */}
                  {!showmore ? (
                    <>
                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("ShipmentNumber")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.ShipmentID ?? t("N/A")}
                        >
                          {detailsdata?.ShipmentID ?? t("N/A")}
                        </p>
                      </div>
                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("House Bill of Lading")}{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.HouseBillNumber ?? t("N/A")}
                        >
                          {detailsdata?.HouseBillNumber ?? t("N/A")}
                        </p>
                      </div>
                      <div
                        className={
                          location?.state?.item?.TransportMode === "SEA"
                            ? "d-none"
                            : "last-sectionlast"
                        }
                      >
                        <p className="last-container-paraone">
                          {location.state.item.TransportMode === "SEA"
                            ? null
                            : "Master Airway Bill Number :"}
                        </p>
                        {location.state.item.TransportMode === "SEA" ? null : (
                          <p
                            className="last-container-childmid"
                            title={detailsdata?.MasterBillNumber ?? t("N/A")}
                          >
                            {detailsdata?.MasterBillNumber ?? t("N/A")}
                          </p>
                        )}
                      </div>
                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {" "}
                          {t("ContainerMode")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.ContainerMode ?? t("N/A")}
                        >
                          {detailsdata?.ContainerMode ?? t("N/A")}
                        </p>
                      </div>
                      {location.state.item.TransportMode === "AIR" ? null : (
                        <>
                          <div className="last-sectionlast">
                            <p className="last-container-paraone">
                              {t("ContainerCount")}:{" "}
                            </p>
                            <p
                              className="last-container-childmid text-ellipsis"
                              title={
                                detailsdata?.ContainerTypeCount ?? t("N/A")
                              }
                            >
                              {detailsdata?.ContainerTypeCount ?? t("N/A")}
                            </p>
                          </div>
                          <div className="last-sectionlast">
                            <p className="last-container-paraone">
                              {t("ContainerNumbers")}:{" "}
                            </p>
                            <p
                              className="last-container-childmid-container text-ellipsis"
                              title={detailsdata?.ContainerNumbers ?? t("N/A")}
                            >
                              {detailsdata?.ContainerNumbers ?? t("N/A")}
                            </p>
                          </div>
                        </>
                      )}

                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("LocalClient")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.LocalClientName ?? t("N/A")}
                        >
                          {detailsdata?.LocalClientName ?? t("N/A")}
                        </p>
                      </div>
                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("Consignee")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.ConsigneeName ?? t("N/A")}
                        >
                          {detailsdata?.ConsigneeName ?? t("N/A")}
                        </p>
                      </div>
                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {location.state.item.TransportMode === "SEA"
                            ? t("Carrier")
                            : t("Airline")}
                          :
                        </p>
                        <p
                          className="last-container-childmid"
                          title={detailsdata?.CarrierName ?? t("N/A")}
                        >
                          {detailsdata?.CarrierName ?? t("N/A")}
                        </p>
                      </div>
                      {location.state.item.TransportMode === "AIR" ? null : (
                        <>
                          {location.state.item.TransportMode ===
                          "SEA" ? null : (
                            <div className="last-sectionlast">
                              <p className="last-container-paraone">
                                {location.state.item.TransportMode === "SEA"
                                  ? "Voyage:"
                                  : "Flight"}
                                :
                              </p>
                              <p className="last-container-childmid">
                                {detailsdata?.Voyage ?? t("N/A")}
                              </p>
                            </div>
                          )}
                        </>
                      )}

                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("Weight")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={`${detailsdata?.Weight ?? t("N/A")} ${
                            detailsdata?.WeightUnit ?? t("N/A")
                          }`}
                        >
                          {detailsdata?.Volume
                            ? `${
                                calculateAmmount(detailsdata?.Weight) ??
                                t("N/A")
                              } ${detailsdata?.WeightUnit ?? t("N/A")}`
                            : ""}
                        </p>
                      </div>

                      <div className="last-sectionlast">
                        <p className="last-container-paraone">
                          {t("Volume")}:{" "}
                        </p>
                        <p
                          className="last-container-childmid"
                          title={`${detailsdata?.Volume ?? t("N/A")} ${
                            detailsdata?.VolumeUnit ?? t("N/A")
                          }`}
                        >
                          {detailsdata?.Volume
                            ? `${
                                calculateAmmount(detailsdata?.Volume) ??
                                t("N/A")
                              } ${detailsdata?.VolumeUnit ?? t("N/A")}`
                            : ""}
                        </p>
                      </div>
                      
                      <div
                        className={
                          location?.state?.item?.TransportMode === "SEA" &&
                          detailsdata?.DemurrageFreeTime > 0
                            ? "last-sectionlast 55"
                            : "d-none"
                        }
                      >
                        <p className="last-container-paraone">
                          {location.state.item.TransportMode === "SEA" &&
                          detailsdata?.DemurrageFreeTime > 0
                            ? "Free Time :"
                            : null}
                        </p>
                        {location.state.item.TransportMode === "SEA" ? (
                          <p
                            className="last-container-childmid"
                            title={detailsdata?.DemurrageFreeTime ?? t("N/A")}
                          >
                            {detailsdata?.DemurrageFreeTime > 0
                              ? detailsdata?.DemurrageFreeTime
                              : null}
                          </p>
                        ) : null}
                      </div>

                      {location.state.item.TransportMode === "AIR" ? null : (
                        <>
                          {location.state.item.TransportMode ===
                          "SEA" ? null : (
                            <div className="last-sectionlast d-none">
                              <p className="last-container-paraone">
                                {location.state.item.TransportMode === "SEA"
                                  ? t("Ship")
                                  : "Aircraft"}
                                :{" "}
                              </p>
                              <p
                                className="last-container-childmid"
                                title={`${detailsdata?.Vessel ?? t("N/A")}
            `}
                              >{`${detailsdata?.Vessel ?? t("N/A")}
          `}</p>
                            </div>
                          )}
                        </>
                      )}
                      {/* this div just for the next line */}
                      {location.state.item.TransportMode === "AIR" ? (
                        <>
                          <div className="last-sectionlast ">
                            <p className="last-container-paraone">
                              {t("First Flight")}:
                            </p>
                            <p
                              className="last-container-childmid"
                              title={getLoadDischargeText(
                                detailsdata?.FirstLoadPortName,
                                detailsdata?.FirstDischargePortName,
                                detailsdata?.Voyage,
                                t
                              )}
                            >
                              {getLoadDischargeText(
                                detailsdata?.FirstLoadPortCode,
                                detailsdata?.FirstDischargePortCode,
                                detailsdata?.Voyage,
                                t
                              )}
                            </p>
                          </div>
                          <div className="last-sectionlast ">
                            <p className="last-container-paraone">
                              {t("Last Flight")}:
                            </p>
                            <p
                              className="last-container-childmid"
                              title={getLoadDischargeText(
                                detailsdata?.LastDischargePortName,
                                detailsdata?.LastLoadPortName,
                                detailsdata?.ArrivalVoyage,
                                t
                              )}
                            >
                              {getLoadDischargeText(
                                detailsdata?.LastDischargePortCode,
                                detailsdata?.LastLoadPortCode,
                                detailsdata?.ArrivalVoyage,
                                t
                              )}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="last-sectionlast ">
                            <p className="last-container-paraone">
                              {t("First Load Vessel")}:
                            </p>
                            <p
                              className="last-container-childmid"
                              title={getVesselVoyageText(
                                detailsdata?.Vessel,
                                detailsdata?.Voyage,
                                t
                              )}
                            >
                              {getVesselVoyageText(
                                detailsdata?.Vessel,
                                detailsdata?.Voyage,
                                t
                              )}
                            </p>
                          </div>
                          <div className="last-sectionlast ">
                            <p className="last-container-paraone">
                              {t("Arrival Vessel")}:
                            </p>
                            <p
                              className="last-container-childmid"
                              title={getVesselVoyageText(
                                detailsdata?.ArrivalVessel,
                                detailsdata?.ArrivalVoyage,
                                t
                              )}
                            >
                              {getVesselVoyageText(
                                detailsdata?.ArrivalVessel,
                                detailsdata?.ArrivalVoyage,
                                t
                              )}
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-end buttonline">
                <BtnOutline
                  onClick={() => clickShowmore()}
                  title={showmore ? t("ShowMore") : t("ShowLess")}
                ></BtnOutline>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(React.memo(ShipmentDetailsCard));
