import React, { useState, useEffect } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CircularProgress } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../process/process.css";
import SkeletonOrder from "./components/SkeletonOrder.js";
import { routeConstant } from "../../routing";
import BtnOutline from "../../components/button/BtnOutline";
import { basename } from "../../Config";
import instance from "../../api/interceptor";
import { API_PATH } from "../../constant/apiPath";
import Nodata from "../../components/Nodata";
import { withTranslation } from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import urlResolver from "../../utils/urlResolver";

function Order(props) {
  const navigate = useNavigate();
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMoreError, setShowMoreError] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const location = useLocation();
  const [isSearch, setIsSearch] = useState(0);
  const [nav, setNav] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const TotalCount = useRef(null);

  const [search, setSearch] = useState({
    type: "",
    value: "",
  });
  // const [advancesearch, setAdvancesearch] = useState({
  //   sort: "LastEditedDateTime",
  //   order: "DESC",
  //   mode: "",
  //   status: "",
  // });
  const [advancesearch, setAdvancesearch] = useState({
    sort: "LastEditedDateTime",
    order: "DESC",
    mode: "",
    status: "",
    activeA: false,
    activeS: false,
    activeR: false,
    activeP: false,
    activeL: false,
    activeAr: false,
  });
  const [selectedListItem, setSelectedListItem] = useState(0);
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  useEffect(() => {
    if (location.pathname == "/orders/details") {
      getOrder(0);
    }
    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, []);
  useEffect(() => {
    if (location.pathname == "/orders") {
      getOrder(0);
    }
  }, [location]);
  const closeNav = () => {
    if (nav) {
      setNav(false);
    } else {
      setNav(true);
    }
  };

  const getOrder = (count, orderFromProcess = {}) => {
    if (count === 0) {
      setLoading(true);
      // setOrder([]);
    }
    let sid = "";
    let buyCode = "";
    let ordId = "";
    let splitNo = "";
    if (location?.state?.from === "process") {
      sid = location?.state?.item?.ShipmentID;
      buyCode = location?.state?.item?.BuyerCode;
      ordId = location?.state?.item?.OrderNumber;
      splitNo = location?.state?.item?.OrderSplitNumber
        ? parseInt(location?.state?.item?.OrderSplitNumber)
        : 0;
    }

    if (search.type === "ShipmentId" && search.value.length > 1) {
      sid = search.type === "ShipmentId" ? search.value : "";
    }
    const payload = {
      ShipmentId: sid ?? "",
      OrderID: ordId ?? "",
      OrgCodes: "",
      EmailId: "",
      OriginPortCode: search.type === "Origin" ? search.value : "",
      DestinationPortCode: search.type === "Destination" ? search.value : "",
      CNPJ: search.type === "CNPJ" ? search.value : "",
      PONumber: search.type === "PO Number" ? search.value : "",
      ShipperName: search.type === "ShipperName" ? search.value : "",
      ConsigneeName: search.type === "ConsigneeName" ? search.value : "",
      Status: advancesearch?.status ?? "",

      DestinationPortName:
        search.type === "Destinaion Port" ? search.value : "",
      BuyerCode: buyCode ?? "",
      SplitNumber: splitNo ?? 0,
      SortColumnName: advancesearch.sort ?? "",
      SortDirection: advancesearch?.order ?? "",
      CurrentPage: count,
      PageSize: 20,
      SearchLoading: isSearch ?? 0,
      SearchField: search.type.length ? "" : search.value,
      TransportMode:
        advancesearch?.activeA ||
        advancesearch?.activeS ||
        advancesearch?.activeR
          ? advancesearch?.mode ?? ""
          : "",
    };
    instance
      .post(API_PATH.GET_ORDER_LIST, payload, { signal })
      .then((res) => {
        if (res._IsSuccess) {
          TotalCount.current = res?._TotalItem;
          if (count === 0) {
            const path = `${routeConstant.ORDER}/${routeConstant.ORDERDETAILS}`;
            if (location.pathname === path && res?._Data.length) {
              handleCardClick(res?._Data[0]);
            }
            if (location.pathname === routeConstant.ORDER) {
              handleCardClick(res?._Data[0]);
            }
            setOrderData(res?._Data ?? []);
            if (search.type === "" && search.value === "") {
              setOrderList(res?._Data);
            }
          } else {
            console.log("orderELse");

            setOrderData([...orderData, ...(res?._Data ?? [])]);
          }
        }
        setLoading(false);
        setLoadMore(false);
        setPageCount(count);
      })
      .catch((err) => {
        console.log("error", err.message);
        if (count === 0) {
          setOrderData([]);
          setShowMoreError(err?.response?.data?._Message);
        } else {
          setOrderData([...orderData]);
          setShowMoreError(err?.response?.data?._Message);
        }
        setLoading(false);
        if (!checkAbort) setLoading(false);
        setLoadMore(false);
      });
  };

  const handleCardClick = (item, index) => {
    setSelectedListItem(index ?? 0);
    const path = `${routeConstant.ORDER}/${routeConstant.ORDERDETAILS}`;
    navigate(path, {
      state: {
        item: item,
      },
    });
  };

  const onSearch = (e) => {
    if (search.type && search.value) {
      setIsSearch(1);
      if (e.key === "Enter") {
        // getOrder(0);
        navigate(routeConstant.ORDER, {
          state: {
            from: "orderOnClickSearchOrder",
          },
        });
      }
    } else {
      if (e.key === "Enter") toast.error("Please select both fields");
    }
  };

  const onLoadMore = () => {
    const count = pageCount + 1;
    setLoadMore(true);
    getOrder(count);
  };

  const { t } = props;

  return (
    <div>
      {loading ? (
        <div
          style={{
            // maxWidth: "1640px",
            width: "calc(100% - 40px)",
            margin: "0 auto",
            paddingTop: "50px",
          }}
        >
          <SkeletonOrder card={order} />
        </div>
      ) : (
        <div
          style={{
            // maxWidth: "1640px",
            width: "calc(100% - 40px)",
            margin: "0 auto",
            paddingTop: "50px",
          }}
          className="container-fluid"
        >
          <div className="row ">
            <div
              className="col-12 col-lg-3 left-row-process"
              id="left-process-div"
            >
              <div className="card-head d-flex ">
                <div className="search-filter">
                  <div className="select">
                    <select
                      disabled={nav}
                      className="title-select"
                      value={search.type}
                      onChange={(event) => {
                        const obj = { ...search };
                        obj.type = event.target.value;
                        setSearch(obj);
                      }}
                    >
                      <option value="" disabled selected hidden>
                        {t("Select")}
                      </option>
                      <option value="CNPJ">{t("CNPJ")}</option>
                      <option value="Origin Port">{t("Origin Port")}</option>
                      <option value="Destination Port">
                        {t("Destination Port")}
                      </option>
                      <option value="PO Number">{t("PONumber")}</option>
                      <option value="ShipperName">{t("Shipper")}</option>
                      <option value="ConsigneeName">Consignee</option>
                      <option value="Ship">{t("Vessel")}</option>
                      <option value="ShipmentId">Shipment #</option>
                    </select>
                  </div>
                  <div className="search-div">
                    <input
                      disabled={nav}
                      className="input-search"
                      type="text"
                      placeholder={t("Search")}
                      value={search.value}
                      onKeyPress={onSearch}
                      onChange={(event) => {
                        const obj = { ...search };
                        obj.value = event.target.value;
                        setSearch(obj);
                      }}
                    />
                    {search.value.length ? (
                      <CloseOutlinedIcon
                        style={{ cursor: "pointer", marginLeft: "-20px" }}
                        onClick={() => {
                          const obj = { ...search, type: "", value: "" };
                          setOrderData(orderList);
                          setSearch(obj);
                          handleCardClick(orderList[0]);
                        }}
                      />
                    ) : null}
                    <SearchOutlinedIcon
                      // onClick={() => {
                      //   setPageCount(0);
                      //   getOrder(0);
                      // }}
                      onClick={() => {
                        if (search.type && search.value) {
                          setPageCount(0);
                          getOrder(0);
                        } else {
                          toast.error("Please select both fields");
                        }
                      }}
                      className="search-icon"
                      style={{ cursor: "pointer" }}
                    />
                    {/* <SearchOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (search.value.length > 3 && search.type.length) {
                          onSearch();
                        }
                      }}
                    /> */}
                  </div>
                  <div className="filter-icon">
                    <TuneOutlinedIcon onClick={() => closeNav()} />
                  </div>
                </div>
                {nav ? (
                  <div
                    className="sort-box"
                    style={{
                      accentColor: "#120b5e",
                    }}
                  >
                    <div className="div-sort" value={advancesearch.sort}>
                      <p>{t("SortBy")} :</p>
                      <div className="sort-option-div ">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="CreatedDateTime"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.sort = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.sort === "CreatedDateTime"
                                ? true
                                : false
                            }
                            value="CreatedDateTime"
                          />
                          <label
                            htmlFor="CreatedDateTime"
                            style={{
                              color:
                                advancesearch.sort === "CreatedDateTime"
                                  ? "#120b5e"
                                  : "",
                            }}
                          >
                            {t("Created")}
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="LastEditedDateTime"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.sort = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.sort === "LastEditedDateTime"
                                ? true
                                : false
                            }
                            value="LastEditedDateTime"
                          />
                          <label
                            htmlFor="LastEditedDateTime"
                            style={{
                              color:
                                advancesearch.sort === "LastEditedDateTime"
                                  ? "#120b5e"
                                  : "",
                            }}
                          >
                            {t("LastUpdated")}
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="ETA"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.sort = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.sort === "ETA" ? true : false
                            }
                            value="ETA"
                          />
                          <label
                            htmlFor="ETA"
                            style={{
                              color:
                                advancesearch.sort === "ETA" ? "#120b5e" : "",
                            }}
                          >
                            {t("ETA")}
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="ETD"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.sort = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.sort === "ETD" ? true : false
                            }
                            value="ETD"
                          />
                          <label
                            htmlFor="ETD"
                            style={{
                              color:
                                advancesearch.sort === "ETD" ? "#120b5e" : "",
                            }}
                          >
                            {t("ETD")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="div-sort" value={advancesearch.order}>
                      <p className="order">{t("OrderBy")} :</p>
                      <div className="sort-option-div">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="ASC"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.order = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.order === "ASC" ? true : false
                            }
                            value="ASC"
                          />
                          <label
                            htmlFor="ASC"
                            style={{
                              color:
                                advancesearch.order === "ASC" ? "#120b5e" : "",
                            }}
                          >
                            {t("Ascending")}
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="DESC"
                            onChange={(event) => {
                              const obj = { ...advancesearch };
                              obj.order = event.target.value;
                              setAdvancesearch(obj);
                            }}
                            checked={
                              advancesearch.order === "DESC" ? true : false
                            }
                            value="DESC"
                          />
                          <label
                            htmlFor="DESC"
                            style={{
                              color:
                                advancesearch.order === "DESC" ? "#120b5e" : "",
                            }}
                          >
                            {t("Descending")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="filterby-div">
                      <p>{t("FilterBy")} :</p>
                      <div className="filterby-options">
                        <div className="filterby-div-inner">
                          <div
                            className={
                              advancesearch.mode === "AIR"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id1"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.mode = "AIR";
                              obj.activeA = !obj.activeA;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            {t("Air")}
                          </div>
                          <div
                            className={
                              advancesearch.mode === "SEA"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id2"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.mode = "SEA";
                              obj.activeS = !obj.activeS;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            {t("Sea")}
                          </div>
                          <div
                            className={
                              advancesearch.mode === "ROA"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id3"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.mode = "ROA";
                              obj.activeR = !obj.activeR;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            {t("Land")}
                          </div>
                        </div>
                        <div className="filter-divider"></div>
                        <div className="filterby-div-inner">
                          <div
                            className={
                              advancesearch.status === "PreLoad"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id4"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.status = "PreLoad";
                              obj.activeP = !obj.activeP;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            <p
                              style={{
                                color:
                                  advancesearch.status === "PreLoad"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("PreLoaded")}
                            </p>
                          </div>
                          <div
                            className={
                              advancesearch.status === "Loaded"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id5"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.status = "Loaded";
                              obj.activeL = !obj.activeL;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            <p
                              style={{
                                color:
                                  advancesearch.status === "Loaded"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("Loaded")}
                            </p>
                          </div>
                          <div
                            className={
                              advancesearch.status === "Arrived"
                                ? "filter-child-div active-mode"
                                : "filter-child-div"
                            }
                            id="id6"
                            onClick={() => {
                              const obj = { ...advancesearch };
                              obj.status = "Arrived";
                              obj.activeAr = !obj.activeAr;
                              setAdvancesearch(obj);
                              setActiveFilter("");
                            }}
                          >
                            <p
                              style={{
                                color:
                                  advancesearch.status === "Arrived"
                                    ? "#120b5e"
                                    : "",
                              }}
                            >
                              {t("Arrived")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <BtnOutline
                        title="Search"
                        onClick={() => {
                          setPageCount(0);
                          getOrder(0);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {orderData && orderData.length ? (
                orderData.map((item, index) => {
                  return (
                    <div
                      className={
                        index === selectedListItem
                          ? "card-mid-selected"
                          : "card-mid "
                      }
                      key={index}
                      onClick={() => handleCardClick(item, index)}
                    >
                      <div className="d-flex">
                        <div className="d-flex card-mid-img">
                          {item.TransportMode === "SEA" ? (
                            <img src={urlResolver("icon/icon-ship.svg")} />
                          ) : item.TransportMode === "AIR" ? (
                            <img
                              src={urlResolver("icon/icon-plane-white.svg")}
                            />
                          ) : item.TransportMode === "ROA" ? (
                            <img src={urlResolver("icon/truck_color.svg")} />
                          ) : null}
                          <div className="line m-2"></div>
                        </div>

                        <div className="order-number-cont">
                          <span className="card-mid-head">{t("PONumber")}</span>
                          <p
                            className="card-mid-para"
                            title={item.OrderReference}
                          >
                            {item.OrderReference}
                          </p>
                        </div>
                      </div>

                      <div className="card-div">
                        <ul>
                          <li
                            className={
                              item?.Status === "PreLoaded"
                                ? "card-mid-list colorPreload"
                                : item.Status === "Loaded"
                                ? "card-mid-list colorLoaded"
                                : item.Status === "Arrived"
                                ? "card-mid-list colorArrived"
                                : "card-mid-list"
                            }
                          >
                            {" "}
                            {t(item?.Status ?? t("N/A"))}
                            {/* {item?.Status ?? "N/A"} */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Nodata />
              )}
              {TotalCount.current > orderData.length && (
                <div className=" d-flex justify-content-center buttonline btn-style">
                  {/* <BtnOutline title="Show more" onClick={onLoadMore} /> */}
                  {!loading &&
                  // orderData?.length > 19 &&
                  // orderData?.length % 20 === 0 ?
                  orderData?.length >= 20 &&
                  showMoreError.length === 0 ? (
                    <div className=" d-flex justify-content-center buttonline btn-style">
                      <BtnOutline
                        title={
                          loadMore ? (
                            <CircularProgress
                              size="0.7rem"
                              style={{ color: "var(--color-blue)" }}
                            />
                          ) : (
                            t("ShowMore")
                          )
                        }
                        disabled={loadMore}
                        onClick={() => {
                          onLoadMore();
                          // setPageSize(pageSize + 1);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div
              className="col-12  col-lg-9 mid-container d-flex left-row-process "
              id="left-process-div"
            >
              {orderData && orderData.length ? <Outlet /> : <Nodata />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Order);
