import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { withTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Payment from "../components/Payment";
import ShipmentDetailsCard from "../components/Shipmentdetailscard";
import Documents from "../components/Document";
import CargoDetails from "../components/CargoDetails";
import { basename } from "../../../Config";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { routeConstant } from "../../../routing";
import LocateOnMap from "../components/LocateOnMap";
import Milestone from "../components/Milestone";
import RecivableDocuments from "../components/RecivableDocuments";
import Nodata from "../../../components/Nodata";
import urlResolver from "../../../utils/urlResolver";

function ProcessDetails(props) {
  const location = useLocation();
  const [detailsdata, setDetailsdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentdocs, setPaymentdocs] = useState([]);
  const [boldocs, setBoldocs] = useState([]);
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const controller = new AbortController();
  const signal = controller.signal;
  const [checkAbort, setCheckAbort] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  useEffect(() => {
    if (location?.state?.item?.status === false) {
      setStatusCheck(true);
    } else {
      setStatusCheck(false);
    }
    // if (location.state === null) navigate(routeConstant.DASHBOARD);
    // if (location?.state?.item?.ShipmentID !== undefined) {

    getShipmentDetails(location?.state?.item?.ShipmentID ?? "");
    // setLoading(true);
    // }

    return () => {
      setCheckAbort(true);
      return controller.abort();
    };
  }, [location?.state?.item?.ShipmentID]);

  //  useEffect(() => {
  //   const isCopied = sessionStorage.getItem('copiedURL');

  //     if (!isCopied) {
  //       sessionStorage.setItem('copiedURL', 'true');
  //     } else {
  //       sessionStorage.removeItem('copiedURL');
  //       if (window.performance && performance.navigation.type === 1) {
  //         setIsInitialLoad(false);
  //       } else {
  //         navigate('/dashboard');
  //       }
  //     }
  //   }, [navigate]);

  // const getPaymentDocs = () => {
  //   setLoading(true);

  //   const payload = {
  //     SortColumnName: "",
  //     SortDirection: "",
  //     CurrentPage: 0,
  //     PageSize: 10,
  //     ShipmentID: location?.state?.item?.ShipmentID ?? "",
  //     DocType: "",
  //     Ledger: "AP/AR",
  //   };
  //   instance
  //     .post(API_PATH.GET_RECIVABLE_DOC_SHIPMENT, payload, { signal })
  //     .then((res) => {
  //       if (res._IsSuccess) {
  //         if (res._Data && res._Data.length) {
  //           res._Data.map((item) => {
  //             item.SC_ImageDataHasValue = false;
  //           });
  //         }
  //         let newtemp = res._Data.filter((item) => {
  //           return item.SC_DocType === "BOL";
  //         });
  //         let oldtemp = res._Data.filter((item) => {
  //           return item.SC_DocType != "BOL";
  //         });

  //         setPaymentdocs(
  //           oldtemp.sort((a, b) => {
  //             return new Date(b.SC_Date) - new Date(a.SC_Date);
  //           })
  //         );
  //         setBoldocs(
  //           // newtemp
  //           newtemp.sort((a, b) => {
  //             return new Date(b.SC_Date) - new Date(a.SC_Date);
  //           })
  //         );
  //       } else {
  //         setPaymentdocs([]);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setPaymentdocs([]);
  //       console.log("get recivable doc Api error", err);
  //       if (!checkAbort) setLoading(false);
  //     });
  // };

  const getShipmentDetails = (i) => {
    setLoading(true);
    console.log("onListClick getShipmet", i);
    const payload = {
      ShipmentId: i,
    };
    if (i !== "")
      instance
        .post(API_PATH.GET_SHIPMENT_DETAILS, payload, { signal })
        .then((res) => {
          if (res._IsSuccess) {
            setDetailsdata(res?._Data ?? {});
            // getPaymentDocs();
          } else {
            setDetailsdata({});
            // getPaymentDocs();
          }
          setLoading(false);
        })
        .catch((err) => {
          setDetailsdata({});

          console.log("get shipment details error", err);
          if (!checkAbort) setLoading(false);
        });
    setLoading(false);
  };

  const onOrderClick = (item) => {
    const path = `${routeConstant.ORDER}/${routeConstant.ORDERDETAILS}`;
    navigate(path, {
      state: {
        item: item,
        from: "process",
      },
    });
  };
  const { t } = props;

  if (statusCheck) {
    return <Nodata />;
  }
  console.log(
    "Object.keys(detailsdata)?.length",
    Object.keys(detailsdata)?.length
  );
  return (
    <div className="container-fluid">
      <div className="row">
        <>
          <div className="col-12 col-lg-8 ">
            <ShipmentDetailsCard
              t={t}
              detailsdata={detailsdata}
              // loading={loading}
              loading={Object.keys(detailsdata)?.length === 0 ? true : loading}
            />
            <Payment boldocs={boldocs} t={t} />
            <RecivableDocuments paymentdocs={paymentdocs} t={t} />
            <Documents t={t} />
            <CargoDetails
              t={t}
              loading={loading}
              clientNotes={detailsdata?.ShipClientVIsibleNotes ?? ""}
            />
          </div>
          <div className="col-12 col-lg-4 pe-0 ">
            {loading ? (
              // <Skeleton
              //   variant="rectangular"
              //   height={0}
              //   style={{ width: "100%", marginBottom: "1rem" }}
              // />
              ""
            ) : (
              <div
                style={{
                  maxHeight: "30vh",
                  overflowY: "auto",
                  paddingRight: "0.5rem",
                }}
              >
                {detailsdata?.OrderCollection?.map((item, index) => {
                  return (
                    <div
                      className="map-head d-flex pointer"
                      key={index}
                      onClick={() => {
                        onOrderClick(item);
                      }}
                    >
                      <div className="d-flex   map-img ">
                        <img src={urlResolver("icon/icon-paper.svg")} />
                        <div className=" map-line m-2"></div>
                      </div>
                      <div className="map-top-div">
                        <p className="color-grey">{t("Order")}:</p>
                        <p className="color-blue2">{item?.OrderNumber ?? ""}</p>
                      </div>
                      <div className="map-card-div ">
                        <ArrowForwardIosIcon className="map-card-arrow" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="map-main-comp">
              <div className="map-milestone-div">
                <div className="map-milestone-div-child">
                  <LocateOnMap />
                </div>
                <Milestone />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default withTranslation()(ProcessDetails);
