import { Directions } from "@mui/icons-material";
import instance from "../../../api/interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { ACTION_TYPE } from "../constants/actionType";

export const getShipments =
  (count, search, advancesearch, showstatus, isSearch, signal, TotalCount) =>
  async (dispatch) => {
    console.log("Count: ", search);
    const payload = {
      ShipmentId: search.type === "ShipmentId" ? search.value : "",
      OrgCodes: "",
      EmailId: "",
      OriginPortCode: search.type === "Origin" ? search.value : "",
      DestinationPortCode: search.type === "Destination" ? search.value : "",
      OriginPortName: search.type === "Origin Port" ? search.value : "",
      DestinationPortName:
        search.type === "Destinaion Port" ? search.value : "",
      CNPJ: search.type === "CNPJ" ? search.value : "",
      Ship: search.type === "Ship" ? search.value : "",
      PONumber: search.type === "PO Number" ? search.value : "",
      ShipperName: search.type === "ShipperName" ? search.value : "",
      ConsigneeName: search.type === "ConsigneeName" ? search.value : "",
      Status:
        advancesearch?.activeP ||
        advancesearch?.activeL ||
        advancesearch?.activeAr
          ? advancesearch?.status || ""
          : showstatus ?? "",
      SortColumnName: advancesearch?.sort ?? "",
      SortDirection: advancesearch?.order ?? "",
      CurrentPage: count,
      PageSize: 20,
      TransportMode:
        advancesearch?.activeA ||
        advancesearch?.activeS ||
        advancesearch?.activeR
          ? advancesearch?.mode ?? ""
          : "",
          Direction:
          advancesearch?.activeI ||
          advancesearch?.activeE
            ? advancesearch?.direction ?? ""
            : "",
      SearchLoading: isSearch ?? 0,
      SearchField: search.type === "Ref" ? search.value : "",
    };
    if (!payload.CurrentPage) {
      dispatch({ type: ACTION_TYPE.SHIPMENT_LIST_REQUEST });
    } else {
      dispatch({ type: ACTION_TYPE.LOADING_MORE_SHIPMENT });
    }
    await instance
      .post(API_PATH.GET_SHIPMENT_LIST, payload, { signal })
      .then((data) => {
        if (!payload.CurrentPage) {
          dispatch({
            type: ACTION_TYPE.SHIPMENT_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: ACTION_TYPE.SHIPMENT_LIST_SUCCESS_PAGE,
            payload: data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPE.SHIPMENT_LIST_FAIL,
          error: error,
        });
        console.log("shipment list action fail", error);
      });
  };
